import axios from './axios';

const plumesService = {
  get: () => {
    const options = {
      method: 'GET',
      url: 'plumes'
    };
    return axios(options);
  },
  getData: (id, page = 1, pageSize = 10) => {
    const options = {
      method: 'GET',
      url: `plume-datas/${id}?page=${page}&pageSize=${pageSize}`
    };
    return axios(options);
  },
  getUrlConf: (configURL) => {
    const options = {
      method: 'GET',
      url: configURL
    };
    const baseURL = window.ELLONA_SETTINGS.plumeUrl;
    return axios(options, { baseURL });
  }
};

export default plumesService;
